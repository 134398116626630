/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: BaseEnv.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/Tasks/Badge.ts;
 * Description: Copia di presenze ma associate a persone e con la gestione dei record degli accessi (badge elettronici /orari);
 * Author: giovannibattistadellaporta;
 * Created at: 20/03/24, 11:28;
 * Updated at: 20/03/24, 11:28;
 *
 *
 */

export const Badge = () => { 
    return {
        type: 'K',
        addButton: true,
        managerLoggato: {},
        hideif: { hasNotCapacity: [] },
        restRoute: '/anagrafica/persone/badge',
        Label: '',
        Headers: [
            //{nome: "N.", oggetti: ["id"]},
            { nome: "Stato", oggetti: ['stato'], inputType: 'text' },
            { nome: "Data", oggetti: ['data_inizio_prevista'], inputType: 'datetime-local' },
        ],
        Azioni: [{
            nome: 'Elimina',
            close: true,
            class: ' btn ',
            labelClass: 'ml-1 text-danger font-weight-bold',
            type: 'POST',
            restRoute: '/anagrafica/persone/badge/delete',
            preAlert: 'Sei sicuro di voler eliminare questo elemento?',
            icon: 'fas fa-trash text-danger',
            hideif: { fieldnotexist: 'id' },
            placeholder: 'Elimina',
            successMex: 'Elemento eliminato con successo',
            parameter: [{ nome: "id", parameter: 'id' }]
        }
        ],
       legenda: [
            {
                area: 'Legenda Presenze',
                legenda: [
                    { label: 'Presente', color: '#00ff00' },
                    { label: 'Assente', color: '#ff0000' },
                ],
            },
        ],
        campi: [
            {
                nome: 'Data e Ora',
                oggetti: ['data_attivita'],
                modificabile: true,
                inputType: 'datetime-local',
                class: "col-lg-6",
                required: "true"
            },
            {
                nome: 'Stato',
                required: true,
                source: [{ id: 'P', label: 'Presente' }, { id: 'A', label: 'Assente' }],
                oggetti: ['stato'],
                internalOutput: 'statoObject',
                OutputField: 'stato',
                OnChange: 'statochange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona uno Stato',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-6',
                visibleifProhibited: true
            },
            {
                nome: 'Classificazione',
                source: '/anagrafica/persone/badge/types',
                oggetti: ['tipo'],
                internalOutput: 'tipoObject',
                OutputField: 'tipo',
                OnChange: 'tipochange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona un Tipo',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                disabledif: { fieldnotexist: 'stato', fieldNotEqual: [{ source: 'stato', value: 'A' }] },
                class: 'col-lg-6',
                visibleifProhibited: true
            },

            {
                nome: 'Note',
                oggetti: ['note'],
                modificabile: true,
                inputType: 'textarea',
                class: "col-lg-6",
                requiredif: {
                    fieldEqual: [{ source: 'stato', value: 'AL' }]
                },
            },
            {
                nome: 'Operatore',
                source: '/anagrafica/persone/list',
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 200 }, { nome: "ruolo", value: 'OP' }],
                oggetti: ['manager'],
                internalOutput: 'managerObject',
                responseHasData: true,
                OutputField: 'manager',
                OnChange: 'managerchange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona un Operatore',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullName',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-6',
                visibleifProhibited: true
            },
        ]
    };
}