/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: Fortyfix.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/BaseEnvironments/Fortyfix.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 21/03/24, 15:38;
 * Updated at: 21/03/24, 15:38;
 *
 *
 */



import {getModule, addModule} from "../Utility/ModuleUtility";
import { CrmEnv } from '../Modules/CRM';
import { BadgeEnv } from "../Modules/Badge";
import { MansioniEnv } from "../Modules/Mansioni";
import { FabbisognoFormativoEnv } from "../Modules/FabbisognoFormativo";
import { AttestatiEnv } from "../Modules/Attestati";
var Environment = () => {
  var base =  {
    hasCustomizer: true,
    apiUrl: 'https://demo.fortyfix.com/fortyfix/api',
    Modules:{
      "Interventi": true,
      "Prima_Nota": true,
      "Progetti": true,
      "Prodotti": true,
      "Documenti": true,
      "Scadenzario": true,
      "Stampe": true,
      "Shared": true,
      "Dashboard": true,
      "Formazione": true,
      "Rma": true,
      "Ticket": true,
      "taskboard": true,
      "Camera": true,
      "Valutazioni":true
    },
    ClientiAddOn: {
      sedi: true,
      attivita: true,
      prodotti: true,
      rma: true,
      referenti: true,
      interventi: true,
      commerciale: true,
      segnalatore: true,
      segnalatoreInterno: true,
    },
    RmaAddOn: {
      hasCards: true,
      campiSum: true,
      CampiEditTaskboard:[
        {
          modificabile: true,
          nome: 'Tipo Intervento',
          oggetti: ['tipo'],
          inputType: 'singleDropdown',
          source: '/settings/get',
          parameter: [{nome:"id", value: "tipo_intervento"}, {nome:"type", value: "tipo_intervento"}],
          internalOutput: 'tipoObject',
          OutputField: 'tipo',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput:true,
            idField: 'id',
            textField: 'label',
            allowSearchFilter: true,
          },
          titleClass: 'control-label col-form-label'  ,
          class: 'col-lg-12',
          visibleifProhibited: true
        },

      ]
    },
    TaskAddon: {
      isTuttiVisible: (ListComponent ) => {
        const _permittedRoles: string[] = ['RT', 'Amministrazione', 'SP', 'Commerciale', 'All', 'SC']
        var visible: boolean = false;
        _permittedRoles.forEach(role => {
          var Ra = ListComponent.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x => x.ruolo?.toUpperCase() == role.toUpperCase() || x.id == role);
          var R = ListComponent.AuthService?.currentUserValue?.ruoliObject?.find(x => (typeof x.id == 'string' && x.id?.toUpperCase() == role.toUpperCase()) || x.id == role || x.ruolo?.toUpperCase() == role.toUpperCase());
          visible = visible || Ra != undefined || R != undefined;
        })
        return visible;
      },
      DynamicFields:[{
        type: 'N',
        addButton: true,
        hideif: {hasNotCapacity:['gestione-news']},
        restRoute: '/task/news',
        Label: 'News',
        Azioni:[
          {nome: 'Elimina',close:true, class:' btn ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/task/news/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-news'], icon:'fas fa-trash text-danger', hideif: {fieldnotexist: 'id'}, placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},
        ],
        campi:[
          {nome:'Titolo:', oggetti: ['nome'], modificabile:true ,inputType:'text', class: 'col-lg-12' },
          {nome:'Body:', oggetti: ['note'], inputType: 'blackhtml', modificabile: true, class: 'col-lg-12'},
          {nome:'Data:', oggetti: ['data_inizio_prevista'], modificabile:true ,inputType:'date', class: 'col-lg-3' },
          {nome:'Data Fine:', oggetti: ['data_fine_prevista'], modificabile:true ,inputType:'date', class: 'col-lg-3' },
          {nome:'Publica:', oggetti: ['publicata'], modificabile:true ,inputType:'checkbox', class: 'col-lg-3' },
          {
            type:'image',
            oggetti:['id_immagine'],
            imageToServer: 'immagine',
            filename: 'file',
            titleClass: 'control-label col-form-label',
            class: 'col-md-12',
            visibleifProhibited: true,
            modificabile: true
          },
        ]
      }]
    },
    Menu: [
      {
        title: "Menu",
        class: "has-arrow",
        label: "Menu",
        extralink: true
      },
      {
        module: "Dashboard",
        path: "/dashboard/dashboard",
        title: 'menu.dashboard',
        icon: "mdi mdi-gauge",
        capacita: ["menu-dashboard"]
      },
      {
        module: "Task",
        path: "/task/list/N/0/Vertical",
        title: 'News',
        icon: "fas fa-newspaper",
        capacita: ["menu-news"]
      },
      {
        module: "Personale",
        path: "/listautenti/personale",
        title:'menu.PersonaleLabel',
        icon: "far fa-user",
        capacita: ["menu-persone"]
      },
      // {
      //   module: "Clienti",
      //   capacita: ["menu-clienti"],
      //   path: "/listautenti/aziende/c",
      //   title: 'Clienti',
      //   icon: "mdi mdi-account-multiple",
      // },
      // {
      //   module: "Clienti",
      //   capacita: ["menu-clienti"],
      //   path: "/listautenti/aziende/f",
      //   title: 'Fornitori',
      //   icon: "mdi mdi-account-multiple",
      // },
      {
        module: "Clienti",
        path: "/listautenti/aziende",
        title:'menu.clienti',
        icon: "mdi mdi-account-multiple",
        capacita: ["menu-clienti"]
      },
      {
        module:"Progetti",
        path: "progetti/progetti",
        title: 'menu.progetti',
        class: "has-arrow",
        icon: "mdi mdi-calendar-multiple-check",
        capacita: ["menu-progetti"],
        submenu:[
          {
            module: "Progetti",
            path: "progetti/progetti/CR",
            title: 'Nuovi',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/EM",
            title: 'Emessi',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/AC",
            title: 'Accettati',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/CO",
            title: 'Confermati',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/LA",
            title: 'In lavorazione',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/NE",
            title: 'Non Evasi',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          },
          {
            module: "Progetti",
            path: "progetti/progetti/T",
            title: 'Tutti',
            icon: "mdi mdi-calendar-multiple-check",
            capacita: ["menu-progetti"]
          }
        ]
      },
      {
        module: "Task",
        path: "task/unita",
        title: 'Calendario',
        icon: "mdi mdi-calendar",
      },
      {
        module: "taskboard",
        path: "task/attivita",
        title: 'menu.taskboard',
        icon: "mdi mdi-calendar"
      },
      {
        module: "Listini",
        path: "listino/listini",
        title: 'menu.listini',
        icon: "mdi mdi-calendar"
      },
      {
        module: "Acquisti",
        path: "acquisti/acquisti",
        title: 'menu.acquisti',
        icon: "mdi mdi-calendar"
      },
      {
        module: "Interventi",
        title: "Attività",
        icon: "mdi mdi-wrench",
        path: "interventi/interventi",
        capacita: ["menu-fasi"],
      },
      // {
      //   module: "Interventi",
      //   title: "Attività",
      //   icon: "mdi mdi-wrench",
      //   class: "has-arrow",
      //   capacita: ["menu-fasi"],
      //   submenu: [
      //     {
      //       path: "interventi/interventi",
      //       title: 'menu.interventi',
      //       icon: "mdi mdi-wrench",
      //     },
      //     {
      //       path: "interventi/gantt",
      //       title: "Gantt",
      //       icon: "mdi mdi-chart-areaspline",
      //     },
      //   ]
      // },
      {
        module: "Valutazioni",
        path: "valutazioni/check/lista",
        title: 'menu.Valutazioni',
        class: "has-arrow",
        icon: "mdi mdi-currency-eur",
        capacita: ["menu-val"],
        submenu: [
          {
            path: "valutazioni/lista",
            title: 'menu.Valutazioni',
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-val"]
          }, {
            path: "valutazioni/check/lista",
            title: 'menu.ValutazioniCheck',
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-val"],
          }
        ]
      },
      {
        module: "Documenti",
        path: "progetti/documenti",
        title: 'menu.DocumentiLabel',
        icon: "mdi mdi-file-document",
        capacita: ["menu-documenti"]
      },
      {
        module: "Prodotti",
        path: "progetti/prodotti",
        title: 'menu.ProdottiLabel',
        icon: "mdi mdi-barcode-scan",
        capacita: ["menu-prodotti"]
      },
      {
        module: "Formazione",
        title: 'menu.FormazioneLabel',
        icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
        submenu: [
          {
            path: "formazione/media",
            title: "menu.corsiMultimediali",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-formazione"]
          },
          {
            path: "formazione/corsi",
            title: "menu.corsi",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-calendar-servizi", "menu-servizi"]
          },
          {
            path: "/listautenti/personale/mod",
            title: "menu.docenti",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "/listautenti/personale/tut",
            title: "menu.tutor",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "/listautenti/personale/stu",
            title: "menu.studenti",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "formazione/materie",
            title: "menu.materie",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu_calendar_tirocini"]
          },
          {
            path: "formazione/sedi",
            title: "menu.sedi",
            icon: "mdi mdi-currency-eur",
          }
        ]
      },
      {
        module: "Prima_Nota",
        title: "menu.Prima_NotaLabel",
        icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        capacita: ["menu-spese","menu-fatture"],
        submenu: [
          {
            path: "primanota/fatture",
            title: "menu.fattVendita",
            capacita: ["menu-fatture"]
          },
          // {
          //   path: "/listautenti/aziende/f",
          //   title: "menu.fornitori",
          //   capacita: ["menu-fatture"]
          // },
          {
            path: "primanota/primanota",
            title: "menu.primanota",
            capacita: ["menu-spese"]
          },
          {
            path: "primanota/lista-spese",
            title: "menu.listaspese",
            capacita: ["menu-spese"]
          }
        ]
      },
      {
        module: "Parti",
        path: "progetti/parti",
        title: "menu.PartiLabel",
        icon: "mdi mdi-barcode",
        capacita: ["menu-parti"]
      },
      {
        module: "Parti",
        path: "progetti/parti/categorie-list",
        title: "Categorie Parti",
        icon: "mdi mdi-cart",
        capacita: ["menu-parti"]
      },
      {
        module: "Spedizioni",
        path: "spedizioni/lista",
        title: "menu.SpedizioniLabel",
        icon: "mdi mdi-airplane",
        capacita: ["menu-spedizioni"]
      },
      {
        module: "Stampe",
        path: "stampe/templates",
        title: "menu.StampeLabel",
        icon: "mdi mdi-printer",
        class: "has-arrow",
        capacita: ["menu-stampa","menu-blocchi"],
        submenu: [
          {
            path: "stampe/templates",
            title: "Templates",
            capacita: ["gestione-stampa"]
          },
          {
            path: "stampe/marker",
            title: "Marker",
            capacita: ["menu-stampa"]
          },
          {
            path:"/blocchi/blocchi/list",
            title: "Blocchi",
            capacita: ["menu-blocchi"]
          }
        ]
      },
      {
        module: "Settings",
        path: "settings/settings",
        title: "menu.SettingsLabel",
        icon: "mdi-settings",
        capacita: ["menu-settings"]
      },
      {
        module: "Settings",
        path: "settings/smtp",
        title: "menu.smtp",
        icon: "mdi-settings",
        capacita: ["menu-settings"]
      },
      {
        module: "Admin",
        path: "listautenti/roles",
        title: "Ruoli",
        icon: "mdi mdi-lock-open-outline",
        capacita: ["menu-ruoli"]
      },
      {
        module: "Ticket",
        path: "ticket/list",
        title: "Tickets",
        icon: "mdi mdi-bookmark-outline",
        capacita: ["menu-ticket"]
      },
      {
        notruoliOperativi: ["CA","AC"],
        module: "Ecommerce",
        title: 'menu.EcommerceLabel',
        icon: 'mdi mdi-cart',
        class: 'has-arrow',
        submenu: [
          {
            path: '/ecom/cat',
            title: 'Products',
            icon: 'mdi mdi-cards-variant',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/ecom/cart',
            title: 'Cart',
            icon: 'mdi mdi-cart',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/ecom/orders',
            title: 'Orders',
            icon: 'mdi mdi-chart-pie',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
          }
        ]
      },
      {
        ruoliOperativi:["AC","C","CA"],
        path: 'Cliente/profile',
        title: "Area Riservata",
        icon: 'mdi mdi-cart',
      }
    ],
  };
  var env: any = {};
  env = addModule(CrmEnv(), base);
  env = addModule(BadgeEnv(), env);
  env = addModule(MansioniEnv(), env);
  env = addModule(FabbisognoFormativoEnv(), env);
  env = addModule(AttestatiEnv(), env);
  return env;
}

export var environment = getModule(Environment());
